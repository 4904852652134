$box-shadow-dimensions: 0 0 0 0.2rem;
$placeholder-color: #6c757d;

$theme-colors: (
	'primary': #041e42,
	'success': #79c300,
	'danger': #b20023,
);

.bootstrap-wrapper {
	@import '~bootstrap/scss/bootstrap';
	@import '~react-bootstrap-typeahead/css/Typeahead';

	.rbt {
		&-input-multi {
			// Bootstrap 4 focus style
			&.focus {
				background-color: #fff;
				border-color: #80bdff;
				box-shadow: $box-shadow-dimensions rgba(0, 123, 255, 0.25);
				color: #495057;
				outline: 0;
			}

			&.is-invalid.focus {
				border-color: #dc3545;
				box-shadow: $box-shadow-dimensions rgba(220, 53, 69, 0.25);
			}

			&.is-valid.focus {
				border-color: #28a745;
				box-shadow: $box-shadow-dimensions rgba(40, 167, 69, 0.25);
			}

			input::placeholder {
				color: $placeholder-color;
				opacity: 1;
			}
			// Override height in main CSS file :/
			& .rbt-input-main,
			&.form-control-lg .rbt-input-main,
			&.form-control-sm .rbt-input-main {
				height: auto;
			}
		}

		&-token {
			margin: 1px 3px 2px 0;
		}
	}
	button {
		border-radius: 3px;
		margin: 5px;
	}
}
