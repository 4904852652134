.iconButton {
	cursor: pointer;
}

.inlineIcon {
	display: inline;
}

.large {
	font-size: 20px;
}

.disabled {
	cursor: default;
	opacity: 0.5;
}
