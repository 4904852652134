@import '../../../../theme/variables.module';

.providerInvoiceTitle {
	table {
		td {
			white-space: nowrap;
			vertical-align: center;

			.providerName {
				margin-right: 10px;

				input[type='text']::-webkit-input-placeholder {
					color: #000000;
				}

				input {
					background-color: #fff !important;
					color: #000 !important;
					font-size: $font-xl !important;
				}

				input:last-child {
					display: none !important;
				}
			}

			input {
				font-family: 'Montserrat', Arial, Helvetica, sans-serif;
				margin: 0;
			}

			.invoiceNumber {
				width: 70px;
			}

			.disabledInput {
				width: 70px;
			}
		}
	}
}

.iconButtons {
	width: 40px;
	padding: 0 6px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	.edit,
	.save {
		font-size: $font-md;
	}

	.cancel {
		font-size: $font-lg;
	}
}
