.selectCustomersModal {
	width: 700px;
	padding: 0 50px;
}

.customerFilter {
	min-width: 250px;
	display: flex;
	align-items: center;

	input {
		border: 1px solid #797979 !important;
		border-radius: 3px !important;
		height: 31px !important;
		padding: 3px 28px 3px 3px !important;
		transition: box-shadow 0.25s ease !important;

		&:focus {
			box-shadow: 0 0 3px #1955a6 !important;
		}
	}
}

.customerFilterIcon {
	margin-left: -20px;
}

.customerFilterIconRemove {
	display: none;
}

.selectCustomersModalCardTitle {
	display: flex;
	align-items: center;
	justify-content: space-between;

	& > span {
		color: #000;
	}
}

.selectCustomersModalCardButtons {
	display: flex;

	button {
		font-size: 14px !important;
		height: 22px;
		min-width: 50px;
		padding: 0px 9px;
	}
}

.selectCustomersModalCardListGroup {
	max-height: 300px;
	overflow: auto;

	button {
		display: flex !important;
		align-items: center;
		justify-content: space-between;
		margin: 0 !important;
	}
}

.selectedCustomer {
	background-color: #79c300 !important;
	color: #fff !important;
}
