@import '../../../theme/variables.module';

.titleContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 30px;

	button {
		margin: 0;

		span {
			font-size: $font-sm;
		}
	}
}

.noDataWarning {
	font-size: $font-md;
}
