@import '../../../theme/_variables.module.scss';

.referenceInformation {
	max-width: 400px;
	width: 400px;
}

.referenceInformationTable {
	display: flex;
	justify-content: space-between;
	font-size: $font-large;
}

.referenceInformationLabel {
	padding: 9px 2px 9px 9px;
	text-align: left;
}
