@import 'ui/theme/_variables.module.scss';

$color: #ccc;

.button,
.smallButton {
	border: none;
	border-radius: 3px;
	margin: 5px;
	min-width: 100px;
	height: 30px;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'Montserrat', Arial, Helvetica, sans-serif;
	padding: 0px 11.5px;
	line-height: 0;

	&.disabled {
		cursor: default;
	}

	&.spinningButton {
		padding: 0px 2px;
	}
}

.smallButton {
	font-size: $font-medium;
	margin: 1px;
	height: 20px;
}

.spinner {
	margin-left: 5px;
}

.smallButtonSpinner {
	font-size: 13px;
}
