@import '../../../theme/variables.module';

.invoiceCustomerConfirmModal {
	width: 350px;
	padding: 0 50px;

	.warningIcon {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 24px;
		margin: 30px 0;

		.icon {
			font-size: 48px;
			margin-bottom: 20px;
		}
	}

	.warningMessage {
		font-size: $font-large;
		text-align: center;
	}
}
