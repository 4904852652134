$primary-color: #041e42;

.header-wrapper {
	.navbar {
		background-color: transparent !important;

		.logo {
			padding-left: 10px;
		}

		#logout-button {
			color: $primary-color;
			text-decoration: none;
			font-weight: bold;
			font-size: 0.8em;
			border: none;
			outline: none;
			transition: 0s;
			background-color: transparent;

			span {
				color: $primary-color;
			}

			&:hover,
			&:focus,
			&:focus:hover {
				outline: none;
				color: $primary-color;
				border: none;
				box-shadow: none;
				background-color: #ccc;

				svg {
					fill: $primary-color;
				}
			}
		}
	}
}
