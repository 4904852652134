.flexContainer {
	display: flex;
	justify-content: space-between;
}

.titleButtonContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.buttonContainer {
	display: flex;
	justify-content: space-between;
	width: 300px;

	button {
		white-space: nowrap;
		color: #fff;
		font-size: 14px;
	}
}

.confirmDeleteModal {
	display: flex;
	flex-direction: column;
}

.smlInputBox {
	width: 40px;
	padding-right: 15px;
}

.medInputBox {
	max-width: 190px;
	padding-right: 15px;
}

.iconButtonContainer {
	padding: 15px 10px 0 10px;
}
