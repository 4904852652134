$orange: orange;
$red: #b20023;
$lightRed: #dd0000;
$textColor: black;

$gray: #7c878e;
$lightGray: lighten($gray, 25%);
$blue: #041e42;
$lightBlue: #59cbe8;
$linkBlue: #007bff;
$darkBlue: #21366e;
$purple: #6068b2;
$darkPurple: #21366e;
$green: #79c300;
$black: black;
$white: white;
$darkRed: #960019;
$greenBlue: #48746e;
$persian: #00a572;
$maroon: #800000;
$teal: #008080;
$yellow: #f6cb3c;
$disabledBackground: lighten(#1955a6, 50%);
$disabledColor: black;

$seriesColors: $blue, $green, darken($blue, 10%), darken($green, 10%);

@each $seriesColor in $seriesColors {
	$idx: index($seriesColors, $seriesColor);
	.seriesColor#{$idx} {
		background-color: $seriesColor;
	}
}

@mixin transition($args...) {
	-webkit-transition: $args;
	-moz-transition: $args;
	-ms-transition: $args;
	-o-transition: $args;
	transition: $args;
}

@mixin button($color, $textColor) {
	cursor: pointer;

	@include transition(color 0.2s ease);
	@include transition(background-color 0.2s ease);
	background-color: $color;

	color: $textColor;

	&:disabled {
		background-color: $disabledBackground;
		color: $disabledColor;
	}

	&:hover {
		background-color: darken($color, 10%);
		color: darken($textColor, 20%);

		&:disabled {
			background-color: $disabledBackground;
			color: $disabledColor;
		}
	}
	&:active {
		background-color: darken($color, 20%);
		color: darken($textColor, 30%);

		&:disabled {
			background-color: $disabledBackground;
			color: $disabledColor;
		}
	}
}

$colors: (
	gray: (
		$gray,
		$white,
	),
	lightGray: (
		$lightGray,
		$black,
	),
	red: (
		$red,
		$white,
	),
	lightRed: (
		$lightRed,
		$white,
	),
	green: (
		$green,
		$black,
	),
	blue: (
		$blue,
		$white,
	),
	darkBlue: (
		$darkBlue,
		$white,
	),
	linkBlue: (
		$linkBlue,
		$white,
	),
	orange: (
		$orange,
		$black,
	),
	purple: (
		$purple,
		$white,
	),
	darkPurple: (
		$darkPurple,
		$white,
	),
	lightBlue: (
		$lightBlue,
		$black,
	),
	darkRed: (
		$darkRed,
		$white,
	),
	persian: (
		$persian,
		$white,
	),
	greenBlue: (
		$greenBlue,
		$white,
	),
	maroon: (
		$maroon,
		$white,
	),
	teal: (
		$teal,
		$black,
	),
	yellow: (
		$yellow,
		$black,
	),
	white: (
		$white,
		$black,
	),
);
@each $name, $color in $colors {
	.#{$name} {
		background-color: nth($color, 1);
	}
	.#{$name}Text {
		color: nth($color, 1);
	}
	.#{$name}Button {
		@include button(nth($color, 1), nth($color, 2));
	}
	.#{$name}Border {
		border: solid 1px nth($color, 1);
	}
	.#{$name}BorderBottom {
		border-bottom: solid 1px nth($color, 1);
	}
	.#{$name}Highlight {
		&:hover {
			background-color: mix($white, nth($color, 1), 80%);
		}
	}
	.#{$name}HighlightTooltip {
		background-color: mix($white, nth($color, 1), 40%);
		border-color: mix($white, nth($color, 1), 40%);
		color: nth($color, 2);
	}
	.#{$name}BackgroundWithTextColor {
		background-color: nth($color, 1);
		color: nth($color, 2);
	}
	.#{$name}HoverBackground {
		background-color: nth($color, 1);
		color: nth($color, 2);

		@include transition(color 0.2s ease);
		@include transition(background-color 0.2s ease);

		&:hover {
			background-color: darken(nth($color, 1), 10%);
			color: darken(nth($color, 2), 20%);
		}
	}
}

.disabled {
	color: $disabledColor;
	background-color: $disabledBackground;
}

.disabled {
	color: $disabledColor;
	background-color: $disabledBackground;
}
