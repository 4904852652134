@import 'ui/theme/_variables.module.scss';

.trackingEventsHeader {
	font-size: $font-huge;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	height: 100%;
	padding-bottom: 5px;
}

.addEventButton {
	margin-right: 0px !important;
}

.trackingEventDescriptionColumn {
	max-width: 400px;
}

.deleteIcon {
	min-width: 30px;
}

.trackingEventTimeColumn {
	padding-right: 5px;
}
