@import '../../../../theme/variables.module';

.printStationList {
	width: 100%;
	display: flex;
	flex-direction: column;

	.printStationListTitleButtons {
		margin: 20px 0 10px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.printStationListTitle {
			font-size: $font-huge;
		}

		div {
			display: flex;
		}

		@media only screen and (max-width: 600px) {
			flex-direction: column;
		}
	}

	table {
		font-size: $font-large;
		border-radius: 10px;
		border: solid 1px lightgrey;

		thead {
			tr {
				height: 2.5em;

				th {
					padding: 0 10px;
					border: solid 1px lightgrey;
				}
			}
		}

		tbody {
			tr {
				height: 2.5em;
				vertical-align: middle;

				td {
					vertical-align: middle;
					padding: 0 10px;
					border: solid 1px lightgrey;
				}

				td:first-child {
					min-width: 600px;
				}

				td:last-child > div {
					display: flex;
					justify-content: space-evenly;
					font-size: $font-huge;
				}
			}
		}
	}
}
