@import 'ui/theme/_variables.module.scss';

.alreadyValid {
	font-size: $font-huge;
	color: green;
	display: inline-block;
}

.label {
	font-weight: 500;
}

.providerHeader {
	padding-bottom: 0.1em;
	border-bottom: 1px solid #444;
}

.validateAddressContainer {
	min-width: 350px;
	min-height: 100px;
}

.validatedAddress {
	white-space: nowrap;
}

.contactName {
	font-weight: 600;
}

.addressCompanyLocationDropdown {
	margin: 0;
}
