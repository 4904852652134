@import 'ui/theme/_variables.module.scss';

.shipmentCardNewQuote {
	max-width: 1168px;
}

.shipmentStopCardAccessorialsNewQuote {
	font-size: $font-large;
	min-width: 266px;
	max-width: 266px;
}

.flexWrapNewQuote {
	display: flex;
	flex-wrap: wrap;
	max-width: fit-content;
}

.billToNewQuoteModule {
	flex: 1;
	margin: 0;
}

.detailsNewQuoteModule {
	flex: 2;
	margin-right: 10px;

	.specialInstructionsSection {
		width: 435px;
	}
}

.brokerSellRateModule {
	width: fit-content;
}

.uploadDocumentsModule {
	min-width: 700px;
	max-width: 780px;
}

.clientSelector {
	max-width: 300px;
	margin-bottom: 5px;
}

.getQuotesButton {
	margin-top: 10px;
}
