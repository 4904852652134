@import '../../../../theme/variables.module';

.customerInvoiceDetails {
	span {
		font-size: $font-large;
	}

	.tableWrapper {
		padding: 4px 20px 0 20px;
		display: flex;
		justify-content: space-between;
	}

	table {
		th {
			padding: 8px 0;
			text-align: left;
		}

		td {
			padding: 8px 0;
			font-size: $font-large;
			text-align: right;
		}
	}
}
