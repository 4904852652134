@import '../../../../theme/variables.module';

.generateCodeCard {
	label {
		font-size: $font-sm;
		color: #000;

		margin-bottom: 6px !important;
	}

	input {
		font-size: $font-sm !important;

		height: 26px;
		border-radius: 3px;
		border: 1px solid #797979 !important;

		margin-bottom: 10px !important;
	}

	.generateCodeTitle {
		display: flex;
		align-items: center;
		justify-content: space-between;

		height: 22px;

		.flexEnd {
			display: flex;
			justify-content: flex-end;
			img {
				margin: 0;
				padding: 0;
			}
		}
	}

	.generateCodePreview {
		font-weight: bold;
		font-size: $font-lg;
	}

	.generateCodeCheckbox {
		font-size: $font-sm;
		label {
			width: 100% !important;
			div {
				display: inline-block !important;
			}
		}
	}
}
